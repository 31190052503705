import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Multiselect from "multiselect-react-dropdown";
import { getTags, addQAPair } from "../../api";
import { getToken, useLogin } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

import styles from "./QuestionEvaluation.module.css";
import { IconButton } from "@fluentui/react";
import { DismissFilled } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { Spinner } from "@fluentui/react";
import { Send28Filled } from "@fluentui/react-icons";

interface Props {
    question: string;
}

export const QuestionEvaluation = ({ question }: Props) => {
    const client = useLogin ? useMsal().instance : undefined;
    useEffect(() => {
        const getTagsFormatted = async () => {
            const token = client ? await getToken(client) : undefined;
            const tagsArray = await getTags(token);
            const tags = tagsArray.map(tag => ({ name: tag }));

            setTags(tags);
        };

        getTagsFormatted();
    }, []);

    const [onEvalaluationClicked, setOnEvaluationClicked] = useState<boolean>(false);
    const [send, setSend] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [rightAnswer, setRightAnswer] = useState<string>("");
    const [tags, setTags] = useState<object[]>([]);
    const [inputError, setInputError] = useState<boolean>(false);

    const multiselectRef = React.useRef<Multiselect>(null);

    const sendRightAnswer = async (question: string, rightAnswer: string, tags: string[]) => {
        setSending(true);
        const token = client ? await getToken(client) : undefined;
        const result = await addQAPair(question, rightAnswer, tags, token);
        if (result == "Success") {
            setSend(true);
        }
        setSending(false);
    };

    return (
        <div className={styles.outerContainer}>
            {!onEvalaluationClicked && !send && (
                <div className={styles.container}>
                    <div className={styles.evaluationInputContainer}>
                        <div className={styles.text}>Bewerte die Antwort</div>
                        <IconButton style={{ color: "gray" }} iconProps={{ iconName: "EmojiNeutral" }} onClick={() => setOnEvaluationClicked(true)} />
                        <IconButton style={{ color: "red" }} iconProps={{ iconName: "EmojiDisappointed" }} onClick={() => setOnEvaluationClicked(true)} />
                    </div>
                </div>
            )}
            {onEvalaluationClicked && !send && (
                <div className={styles.containerClicked}>
                    <div className={styles.tagsContainer}>
                        <div>Themen: </div>
                        <Multiselect
                            // className={styles.multiselect}
                            style={{
                                chips: {
                                    background: "transparent",
                                    border: "gray 1px solid",
                                    color: "black",
                                    borderRadius: "5px",
                                    margin: "auto"
                                    // display: "flex",
                                    // justifyContent: "flex-start"
                                },
                                searchBox: { border: "none" },
                                option: { color: "gray" }
                            }}
                            ref={multiselectRef}
                            options={tags} // Options to display in the dropdown
                            displayValue="name" // Property name to display in the dropdown options
                            placeholder="Wähle Themen aus..."
                            customCloseIcon={<DismissFilled />}
                            disable={sending}
                        />
                    </div>
                    <div className={styles.evaluationInputContainer}>
                        <TextareaAutosize
                            className={!inputError ? styles.textField : styles.textFieldError}
                            placeholder={"Richtige Antwort"}
                            value={rightAnswer}
                            disabled={sending}
                            onChange={e => {
                                setRightAnswer(e.target.value);
                                if (inputError && e.target.value.trim() != "") {
                                    setInputError(false);
                                }
                            }}
                        />
                        {sending && <Spinner />}
                        {!sending && (
                            <Button
                                className={styles.sendButton}
                                size="large"
                                icon={<Send28Filled primaryFill="#9CBF2B" />}
                                onClick={() => {
                                    if (multiselectRef.current && rightAnswer.trim() != "") {
                                        sendRightAnswer(
                                            question,
                                            rightAnswer,
                                            multiselectRef.current.getSelectedItems().map((item: { name: string }) => item.name)
                                        );
                                    } else {
                                        setRightAnswer("");
                                        setInputError(true);
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
            {send && <div className={styles.containerSend}>Danke für deine Bewertung!</div>}
        </div>
    );
};
