const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config } from "./models";
import { useLogin, appServicesToken } from "../authConfig";

function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    return headers;
}

function getFormHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {};
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    return headers;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function askDetailApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/askdetail`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function marketingApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/marketingqa`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function configApi(idToken: string | undefined): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return (await response.json()) as Config;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function chatoriginalApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chatoriginal`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function getDocumentApi(citationFilePath: string, idToken: string | undefined): Promise<string> {
    console.log("Retrieving citation document");

    const response = await fetch(citationFilePath, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    const blob = await response.blob();

    return URL.createObjectURL(blob);
}

export async function appendToBlobApi(dataToAppend: string, idToken: string | undefined): Promise<string> {
    console.log("writing to Blob");

    const response = await fetch(`${BACKEND_URI}/appendtoBlob`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify({ data: dataToAppend })
    });

    if (response.status == 200) {
        return "Success";
    } else {
        console.error("Error appending to Blob:", response.statusText);
        return "Problem";
    }
}

export async function uploadDocument(file: File, idToken: string | undefined): Promise<{ websocket: WebSocket | undefined; result: Promise<string> }> {
    console.log("Uploading document", file.name);

    const requestUploadResponse = await fetch(`${BACKEND_URI}/requestUpload`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify("")
    });

    if (requestUploadResponse.status != 200) {
        console.error("Error requesting upload:", requestUploadResponse.statusText);
        return { websocket: undefined, result: Promise.resolve("Error") };
    }

    const requestUploadResponseJson = await requestUploadResponse.json();
    const uploadUrl = `${BACKEND_URI}${requestUploadResponseJson.location}`;
    const websocketUrl = `${BACKEND_URI}${requestUploadResponseJson.progress_location}`;

    const websocket = new WebSocket(websocketUrl);
    if (!websocket) {
        console.error("Error connecting websocket");
        return { websocket: undefined, result: Promise.resolve("Error") };
    }

    const result = new Promise<string>(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await fetch(uploadUrl, {
                method: "POST",
                headers: getFormHeaders(idToken),
                body: formData
            });

            if (response.status == 200) {
                resolve("Success");
            } else {
                console.error("Error uploading document:", response.statusText);
                reject("Error");
            }
        } catch (error) {
            console.error("Error uploading document:", error);
            reject("Error");
        }
    });

    return { websocket: websocket, result: result };
}

export async function getTags(idToken?: string | undefined): Promise<string[]> {
    console.log("Retrieving tags");

    const response = await fetch(`${BACKEND_URI}/tags`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return (await response.json()).tags as string[];
}

export async function addQAPair(question: string, answer: string, tags: string[], idToken: string | undefined): Promise<string> {
    console.log("Adding QA pair to database");

    const response = await fetch(`${BACKEND_URI}/training`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify({ question: question, answer: answer, tags: tags })
    });

    if (response.status == 200) {
        return "Success";
    } else {
        console.error("Error adding QA pair:", response.statusText);
        return "Error";
    }
}
