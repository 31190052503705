import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import { getUserRoles } from "../../authConfig";

export const ProtectedRoute = ({ allowedRoles, children }: { allowedRoles: string[]; children: React.ReactNode }) => {
    const client = useMsal().instance;
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchRoles = async () => {
            const roles = await getUserRoles(client);
            setUserRoles(roles);
            setIsLoading(false);
        };
        fetchRoles();
    }, [client]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return userRoles.some(role => allowedRoles.includes(role)) ? children : <Navigate to="/unauthorized" />;
};
