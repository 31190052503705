import React from "react";
import styles from "./AdminPanel.module.css";

import { Upload } from "../../components/Upload/Upload";

export const AdminPanel = () => {
    return (
        <div className={styles.container}>
            <Upload />
        </div>
    );
};
