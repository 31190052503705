import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo, InteractionType } from "@azure/msal-browser";
import { msalConfig, useLogin, loginRequest, getRedirectUri, isLoggedIn } from "./authConfig";

import "./index.css";

import Layout from "./pages/layout/Layout";
import { QADetail } from "./pages/qadetail/QADetail";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { AdminPanel } from "./pages/adminPanel/AdminPanel";
import { LoginButton } from "./components/LoginButton/LoginButton";

var layout;

if (useLogin) {
    var msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    layout = (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Layout />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
} else {
    layout = <Layout />;
}

initializeIcons();

const router = createHashRouter([
    {
        path: "/",
        element: layout,
        children: [
            {
                index: true,
                element: <QADetail />
            },
            {
                path: "chatgpt",
                lazy: () => import("./pages/chatgpt/Chatgpt")
            },
            {
                path: "adminPanel",
                element: (
                    <ProtectedRoute allowedRoles={["admin"]}>
                        <AdminPanel />
                    </ProtectedRoute>
                )
            },
            {
                path: "info",
                lazy: () => import("./pages/info/Info")
            },
            {
                path: "unauthorized",
                lazy: () => import("./pages/Unauthorized")
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
