import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getUserRoles } from "../../authConfig";

export const ProtectedComponent = ({ allowedRoles, children }: { allowedRoles: string[]; children: React.ReactNode }) => {
    const { instance } = useMsal();
    const [userRoles, setUserRoles] = useState<string[]>([]);

    useEffect(() => {
        const fetchRoles = async () => {
            const roles = await getUserRoles(instance);
            setUserRoles(roles);
        };

        fetchRoles();
    }, [instance]);

    return userRoles.some(role => allowedRoles.includes(role)) ? children : null;
};
