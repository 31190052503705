import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@mui/material/CircularProgress";
import { Checkmark20Filled, ErrorCircle20Filled, ArrowCounterclockwise20Filled, DocumentPdf32Regular, CloudAdd24Regular } from "@fluentui/react-icons";
import { useMsal } from "@azure/msal-react";

import styles from "./Upload.module.css";
import { uploadDocument } from "../../api";
import { Button, Tooltip } from "@fluentui/react-components";
import { getToken } from "../../authConfig";

class FileUpload {
    file: File;
    setProgress: React.Dispatch<React.SetStateAction<number>>;
    setFailed: React.Dispatch<React.SetStateAction<boolean>>;
    setUploadComplete: React.Dispatch<React.SetStateAction<boolean>>;
    msalInstance: any;

    constructor(
        file: File,
        setProgress: React.Dispatch<React.SetStateAction<number>>,
        setFailed: React.Dispatch<React.SetStateAction<boolean>>,
        setUploadComplete: React.Dispatch<React.SetStateAction<boolean>>,
        msalInstance: any
    ) {
        this.file = file;
        this.setProgress = setProgress;
        this.setFailed = setFailed;
        this.setUploadComplete = setUploadComplete;
        this.msalInstance = msalInstance;
    }

    async startUpload() {
        this.setFailed(false);
        const token = await getToken(this.msalInstance);
        const { websocket, result } = await uploadDocument(this.file, token);

        if (websocket) {
            websocket.onmessage = event => {
                this.setProgress(Math.round(parseFloat(event.data) * 100));
            };
        }

        result.then((result: string) => {
            if (result === "Success") {
                this.setUploadComplete(true);
            } else {
                this.setFailed(true);
            }
        });
    }

    cancelUpload() {}
}

const FileUploadComponent: React.FC<{ file: File }> = ({ file }) => {
    const msalInstance = useMsal().instance;

    const [progress, setProgress] = useState(0);
    const [failed, setFailed] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const fileUpload = new FileUpload(file, setProgress, setFailed, setUploadComplete, msalInstance);
    const [removeFromList, setRemoveFromList] = useState(false);

    useEffect(() => {
        if (!uploadComplete) {
            fileUpload.startUpload();
        }
    }, []);

    useEffect(() => {
        if (uploadComplete) {
            setTimeout(() => {
                setRemoveFromList(true);
            }, 5500);
        }
    }, [uploadComplete]);

    return removeFromList ? null : (
        <div className={uploadComplete ? styles.fileContainerTransition : styles.fileContainer}>
            <DocumentPdf32Regular className={styles.fileIcon} />
            <div className={styles.fileNameContainer}>
                <p className={styles.fileName}>{file.name}</p>
                <p className={styles.fileSize}> - {Math.trunc((file.size / (1024 * 1024)) * 100) / 100} Mbytes</p>
            </div>
            <div className={styles.fillEmptySpace} />
            {!failed && !uploadComplete && (
                <div className={styles.progressParent}>
                    <CircularProgress size="30px" variant={progress == 100 ? "indeterminate" : "determinate"} value={progress} />
                    {progress == 100 ? null : <div className={styles.progressChildren}> {progress}% </div>}
                </div>
            )}
            {failed && (
                <Tooltip content="Retry upload" positioning={"after"} showDelay={0} hideDelay={0} relationship="label">
                    <Button
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => {
                            fileUpload.startUpload();
                            setProgress(0);
                        }}
                        icon={isHovered ? <ArrowCounterclockwise20Filled /> : <ErrorCircle20Filled className={styles.errorIcon} />}
                    />
                </Tooltip>
            )}
            {uploadComplete && <Checkmark20Filled className={styles.checkmarkIcon} />}
        </div>
    );
};

export const Upload = () => {
    const [fileComponents, setFileComponents] = useState<React.ReactNode[]>([]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const newFileComponents = acceptedFiles.map(file => <FileUploadComponent key={file.name} file={file} />);
            setFileComponents([...fileComponents, ...newFileComponents]);
        },
        [fileComponents]
    );

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        onDrop,
        // disabled: currentlyUploading,
        maxFiles: 5,
        accept: { "application/pdf": [".pdf"] }
    });

    return (
        <div className={styles.container}>
            <div className={styles.containerDropzone}>
                <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className={styles.uploadInnerContainer}>
                        <CloudAdd24Regular />
                        <p>Drag file or click here to upload</p>
                    </div>
                </div>
            </div>
            <div className={styles.outerFileContainer}>{fileComponents}</div>
        </div>
    );
};
